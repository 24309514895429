import { createAsync } from '@solidjs/router';
import { TextLink } from '@troon/ui';
import { Match, Suspense, Switch, createUniqueId, Show } from 'solid-js';
import { UserRewardsLevel, gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import type { RouteDefinition } from '@solidjs/router';

const query = gql(`
query userRewards {
  userRewards {
		availablePoints
		currentYearPoints
		previousYear1Points
		previousYear2Points
		previousYear3Points
		level
		pointsToNextLevel
		nextLevel
	}
}`);

const getRewards = cachedQuery(query);

export const route = {
	preload: () => getRewards({}),
} satisfies RouteDefinition;

export default function Settings() {
	const rewards = createAsync(() => getRewards({}));
	const progressId = createUniqueId();

	return (
		<div class="flex flex-col gap-4 md:gap-8">
			<h1 class="text-3xl font-semibold">My account</h1>
			<div class="flex flex-wrap items-start justify-between rounded-lg border border-neutral-500 p-4 md:p-8">
				<div>
					<h2 class="mb-4">Total points available</h2>
					<p class="text-5xl font-semibold">
						<Suspense fallback="…">{pointFormatter(rewards()?.userRewards.availablePoints ?? 0)}</Suspense>
					</p>
				</div>
				<div>
					<TextLink href="/account/activity" class="font-semibold">
						Activity
					</TextLink>
				</div>
			</div>

			<div>
				<h2 class="text-lg font-semibold">Rewards status</h2>
				<p class="mb-4">
					The more you play, the more you save!{' '}
					<Switch>
						<Match when={rewards()?.userRewards.nextLevel}>
							{(nextLevel) => (
								<>
									Reach <Suspense fallback="…">{levelToTitle[nextLevel()]}</Suspense> status to unlock{' '}
									<Suspense fallback="…">{levelRewardsPercent[nextLevel()]}%</Suspense> off tee times.
								</>
							)}
						</Match>
						<Match when={rewards() && !rewards()?.userRewards.nextLevel}>
							Get <Suspense fallback="…">{levelRewardsPercent[rewards()!.userRewards.nextLevel!]}</Suspense> off tee
							times.
						</Match>
					</Switch>
				</p>

				<p>
					<span class="text-lg font-semibold">
						<Suspense fallback="…">{pointFormatter(rewards()?.userRewards.currentYearPoints ?? 0)}</Suspense>
					</span>{' '}
					<span class="text-neutral-700">
						points earned (<abbr title="Year to date">YTD</abbr>)
					</span>
				</p>

				<div
					role="progressbar"
					aria-valuenow={rewards()?.userRewards.availablePoints}
					aria-valuemin={0}
					aria-valuemax={
						(rewards()?.userRewards.availablePoints ?? 0) + (rewards()?.userRewards.pointsToNextLevel ?? 0)
					}
					aria-valuetext={`${pointFormatter(rewards()?.userRewards.availablePoints ?? 0)} points`}
					aria-labelledby={progressId}
					class="h-6 rounded-full bg-brand-100"
				>
					<div
						class="h-6 min-w-6 rounded-full bg-brand"
						style={{
							width: `${((rewards()?.userRewards.availablePoints ?? 0) / ((rewards()?.userRewards.availablePoints ?? 0) + (rewards()?.userRewards.pointsToNextLevel ?? 0))) * 100}%`,
						}}
					/>
				</div>

				<Show when={rewards()?.userRewards.nextLevel}>
					<p class="text-right leading-none">
						<span class="block text-lg font-semibold">
							{pointFormatter(
								(rewards()?.userRewards.availablePoints ?? 0) + (rewards()?.userRewards.pointsToNextLevel ?? 0),
							)}
						</span>
						<Suspense>
							<span class="text-neutral-700">{levelToTitle[rewards()!.userRewards.nextLevel!]}</span>
						</Suspense>
					</p>
				</Show>
			</div>
		</div>
	);
}

const pointFormatter = new Intl.NumberFormat('en', {}).format;

const levelRewardsPercent: Record<UserRewardsLevel, number> = {
	[UserRewardsLevel.Member]: 0,
	[UserRewardsLevel.Silver]: 10,
	[UserRewardsLevel.Gold]: 15,
	[UserRewardsLevel.Platinum]: 20,
};

const levelToTitle: Record<UserRewardsLevel, string> = {
	[UserRewardsLevel.Member]: 'Member',
	[UserRewardsLevel.Silver]: 'Silver',
	[UserRewardsLevel.Gold]: 'Gold',
	[UserRewardsLevel.Platinum]: 'Platinum',
};
